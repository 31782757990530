import { APP_SECTION, SECTION_TO_ROUTE } from '../config/constants/routes';

export const BASE_DOMAIN = 'kover.ru';

export const PRODUCT_AVAILABILITY = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  VIRTUAL: 'virtual',
  RESERVE: 'reserve',
} as const;

const LEGACY_BLACKLIST = [
  // TODO: Add product and all new pages
  // SECTION_TO_ROUTE[APP_SECTION.PRODUCT],
  SECTION_TO_ROUTE[APP_SECTION.CATALOG],
  SECTION_TO_ROUTE[APP_SECTION.MODELI],
  SECTION_TO_ROUTE[APP_SECTION.FAQ],
  SECTION_TO_ROUTE[APP_SECTION.VOZVRAT],
  SECTION_TO_ROUTE[APP_SECTION.DECORATORS],
  SECTION_TO_ROUTE[APP_SECTION.DESIGNER],
  SECTION_TO_ROUTE[APP_SECTION.INFLUENSERS],
];

export const isLegacyStyleEnabled = (pathname: string) => !LEGACY_BLACKLIST.some((i) => pathname.includes(i));

export default isLegacyStyleEnabled;
