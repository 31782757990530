/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { InfoContext, PagePropsContext } from '../../../../contexts';
import { declOfNum } from '../../../../helpers/tools';
import ArrowMiniDownIcon from '../../../../icons/ArrowMiniDownIcon';
import PointIcon from '../../../../icons/PointIcon';
import Typography from '../../../Typography';

import styles from './HeaderHints.module.css';

import qrIcon from '/assets/imgs/header/qr.svg';
import returnIcon from '/assets/imgs/header/return.svg';
import singleCarpetIcon from '/assets/imgs/header/singleCarpet.svg';
import starIcon from '/assets/imgs/header/star.svg';
import truckIcon from '/assets/imgs/header/truck.svg';
import SvgIcon from '/components/SvgIcon';

// TODO: add feature toggle for anticovid banner and discount banner

const HeaderHints = ({ shopsCount, currentShopsCount, showModal }) => {
  const { region } = useContext(PagePropsContext);
  const info = useContext(InfoContext);

  const trackShopsClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Адреса магазинов',
      eventLabel: `${shopsCount || 45} ${declOfNum(shopsCount || 45, ['магазин', 'магазина', 'магазинов'])}`,
    });
  };

  const trackRegionsClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Верхнее меню',
      eventAction: 'Выбор региона',
      eventLabel: region?.name,
    });
  };

  const handleShopsClick = () => {
    trackShopsClick();
  };

  const handleRegionSelectClick = () => {
    showModal('RegionSelect');

    trackRegionsClick();
  };

  return (
    <div className={styles.headerHints}>
      <div className={styles.headerHintsContent}>
        <div className={styles.headerHintsShopLinks}>
          <Link prefetch={false} href='/adresa-magazinov' passHref legacyBehavior>
            <Typography
              variant='body3'
              component='a'
              bold
              className={styles.headerHintsShopLink}
              onClick={handleShopsClick}
            >
              <div className={styles.headerHintsIcon}><PointIcon /></div>
              {shopsCount}
              &nbsp;
              {declOfNum(shopsCount, ['магазин', 'магазина', 'магазинов'])}
            </Typography>
          </Link>

          {!!region && (
            <Typography
              variant='body3'
              component='button'
              bold
              className={styles.headerHintsRegionLink}
              onClick={handleRegionSelectClick}
              type='button'
              data-region-id={info?.digineticaSettings?.feedId || ''}
            >
              {region?.name}
              &nbsp; ({region?.cityShopsCount ?? currentShopsCount}) &nbsp;
              <ArrowMiniDownIcon />
            </Typography>
          )}
        </div>

        <div className={styles.headerHintsFeatures}>
          {!info?.isFreeShippingDisabled && (
            <Link prefetch={false} href='/dostavka-i-oplata/#shipping' passHref legacyBehavior>
              <Typography variant='body3' component='a' className={styles.headerHintsFeature}>
                <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={truckIcon} />
                Бесплатная доставка
              </Typography>
            </Link>
          )}

          {false && (
            <Link prefetch={false} href='/vozvrat' passHref legacyBehavior>
              <Typography variant='body3' component='span' className={styles.headerHintsFeature}>
                <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={qrIcon} />
                Весь персонал имеет QR коды
              </Typography>
            </Link>
          )}

          <Link prefetch={false} href='/vozvrat' passHref legacyBehavior>
            <Typography variant='body3' component='a' className={styles.headerHintsFeature}>
              <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={returnIcon} />
              Бесплатный возврат 100 дней
            </Typography>
          </Link>

          <Link prefetch={false} href='/catalog' passHref legacyBehavior>
            <Typography variant='body3' component='a' className={styles.headerHintsFeature}>
              <SvgIcon height='24' width='24' className={styles.headerHintsIcon} svg={singleCarpetIcon} />
              Все ковры в наличии
            </Typography>
          </Link>

          <Link prefetch={false} href='/reviews' passHref legacyBehavior>
            <Typography variant='body3' component='a' className={styles.headerHintsReviews}>
              <div className={styles.headerHintsReviewIcon}>
                <SvgIcon height='24' width='24' svg={starIcon} />
              </div>
              Отзывы
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

HeaderHints.propTypes = {
  shopsCount: PropTypes.number,
  currentShopsCount: PropTypes.number,
  region: PropTypes.object,
  showModal: PropTypes.func.isRequired,
};

HeaderHints.defaultProps = {
  shopsCount: null,
  currentShopsCount: null,
  region: null,
};

export default HeaderHints;
