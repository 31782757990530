import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import { PageInfoByRegion } from '@/core/model/region/type';
import { ArticleItem } from '@/core/model/type';
import useAppUi from '@/core/store/ui/useAppUi';

import CloseIcon from '../../../../icons/CloseIcon';
import SearchIcon from '../../../../icons/SearchIcon';
import HeaderDropdownAction from '../HeaderDropdownAction';
import HeaderDropdownMenu from '../HeaderDropdownMenu';

import styles from './HeaderContent.module.css';

interface HeaderContentProps {
  articles: ArticleItem[];
  productsCount: number;
  onCloseDropdown: () => void;
  allArticles: any;
  getAllArticles: () => void;
  onGetAllArticles: () => void;
  info: PageInfoByRegion;
  showModal: (name: string, data: any, onClose: null, props: any) => void;
}

const ChoiceDropdown = dynamic(() => import('../ChoiceDropdown'), { ssr: false });

const HeaderContent = (props: HeaderContentProps) => {
  const { onCloseDropdown, info, allArticles, onGetAllArticles } = props;

  const searchInputRef: any = useRef<HTMLInputElement | undefined>();

  const [openedDropdown, setOpenedDropdown] = useState<string>();

  const { setLayout } = useAppUi();

  const makeHandleToggleDropdown =
    (key: string) =>
    ({ isOpen }) => {
      if (isOpen) {
        setLayout({ menu: { opened: true } });
        setTimeout(() => {
          setOpenedDropdown(key);
        }, 100);
      }

      if (!isOpen && key === openedDropdown) {
        setLayout({ menu: { opened: false } });
        setTimeout(() => {
          setOpenedDropdown('');

          onCloseDropdown?.();
        }, 100);
      }
    };

  useEffect(() => {
    if (openedDropdown === 'search') {
      const searchInput = document.querySelector(
        '.header-content .search-dropdown input[name="q"]'
      ) as HTMLInputElement;

      if (searchInput) {
        searchInput.focus();
      }
    }
  }, [openedDropdown]);

  const makeHandleCloseDropdown = (key) => () => {
    if (key === openedDropdown) {
      setOpenedDropdown('');
    }

    if (onCloseDropdown) {
      onCloseDropdown();
    }
  };

  const handleClearSearchClick = () => {
    if (searchInputRef?.current?.value) {
      searchInputRef.current.value = '';

      searchInputRef.current.focus();
    }
  };

  return (
    <div className={classnames(styles.headerContent, 'header-content')}>
      <div className={styles.headerContentGroups}>
        <div className={styles.headerContentGroup}>
          <HeaderDropdownMenu
            isOpen={openedDropdown === 'all'}
            onToggleDropdown={makeHandleToggleDropdown('all')}
            info={info}
            makeHandleCloseDropdown={makeHandleCloseDropdown}
          />

          <HeaderDropdownAction prefetch={false} href='/adresa-magazinov/' title='Адреса магазинов' />

          <HeaderDropdownAction prefetch={false} href='/designer/' title='Дизайнерам' />

          <HeaderDropdownAction prefetch={false} href='/atelier/' title='Ковры на заказ' />

          <HeaderDropdownAction prefetch={false} href='/kontakty/' title='Контакты' />

          <HeaderDropdownAction prefetch={false} href='/decorators/' title='Наши декораторы' />
        </div>

        <div className={styles.headerContentGroupGap} />

        <div className={classnames(styles.headerContentGroup, styles.wide)}>
          <form className={styles.action}>
            <label htmlFor='search-desktop' className={styles.actionIcon}>
              <SearchIcon />
            </label>

            <input
              id='search-desktop'
              ref={searchInputRef}
              className={classnames(styles.actionInput, 'search-input', 'search-input__desktop')}
              placeholder='Поиск'
            />

            <button type='button' className={styles.actionClose} onClick={handleClearSearchClick}>
              <CloseIcon />
            </button>
          </form>
        </div>

        <div className={styles.headerContentGroupGap} />

        <div className={styles.headerContentGroup}>
          <HeaderDropdownAction
            title='Как выбрать ковёр'
            isHoverable
            isOpen={openedDropdown === 'blog'}
            onToggleDropdown={makeHandleToggleDropdown('blog')}
            dropdown={
              <ChoiceDropdown
                className={styles.choiceDropdown}
                allArticles={allArticles}
                onGetAllArticles={onGetAllArticles}
                onCloseDropdown={makeHandleCloseDropdown('blog')}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
