import React from 'react';
import MDSpinner from 'react-md-spinner';
import classnames from 'classnames';

import styles from './Loader.module.css';


const Loader = ({
  isFetching, className, classNameBody, fixed, size, color,
}: any) => (
  <div className={classnames(styles.loaderContent, className, { [styles.active]: isFetching, [styles.fixed]: fixed })}>
    <MDSpinner
      className={classNameBody}
      singleColor={color || '#191E80'}
      size={size || '48px'}
    />
  </div>
);

export default Loader;
