/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { useAmp } from 'next/amp';
import dynamic from 'next/dynamic';
import Error from 'next/error';
import Head from 'next/head';
import { useRouter, withRouter } from 'next/router';
import PropTypes from 'prop-types';

import { COOKIE_NAME, isLegacyStyleEnabled, useAppStore, useAppUi, useRegionId } from '@/core';
import { isClientDebug } from '@/shared/lib/helpers/predicates';

import Footer from '../../../../components/Footer';
import Header from '../../../../components/Header';
import NotFound from '../../../../components/NotFound';
import Progress from '../../../../components/Progress';
import cookies from '../../../../constants/cookies';
import {
  BannerObserverContext,
  DefaultWidthContext,
  InfoContext,
  PagePropsContext,
  ProductItemObserverContext,
  UserAgentContext,
} from '../../../../contexts';
import getAllArticles from '../../../../helpers/getAllArticles';
import getBaseDomain from '../../../../helpers/getBaseDomain';
import getDefaultWidth from '../../../../helpers/getDefaultWidth';
import useCookie from '../../../../hooks/useCookie';
import useGclid from '../../../../hooks/useGclid';
import useViewportWatcher from '../../../../src/application/config/useViewportWatcher';
import { BANNER_ID, BlackFridayStage } from '../../../../src/core/store/ui';
import useRouteEvent from '../../../../src/shared/hooks/useRouteEvent';
import BlackFridayBanner from '../BlackFridayBanner';
import DemonstrationBanner from '../DemonstrationBanner';

import styles from './Layout.module.css';

const DynamicDialog = dynamic(() => import('../Dialog'), { ssr: false });

const legacyStyle = `
  * {
    text-decoration: none;
    box-sizing: inherit;
    position: relative;
    margin: 0;
    padding: 0;
  }
`;

function trackPageView(url, regions) {
  if (process.browser) {
    const baseDomain = getBaseDomain({ regions, host: global?.window?.location.host });

    if (window && window.ga) {
      window?.ga('set', 'page', url);
      window?.ga('send', 'pageview', url);
    }

    if (window && window.ym) {
      window?.ym('46651908', 'hit', url);
      window?.ym('37203035', 'hit', url);
    }

    // const isSafetyPopupShowed = Cookie.get('is-safety-popup-showed');
    const pageCounter = Cookie.get('page-counter') || 0;

    Cookie.set('page-counter', +pageCounter + 1, { domain: baseDomain });
  }
}

const Layout = (props) => {
  const {
    favorites,
    children,
    contacts,
    contactsData,
    footerData,
    info,
    product,
    modalChildrens,
    showModal,
    isError,
    error,
    isRegionConfirmationShowed,
    hideModal,
    experimentId,
    experimentVersion,
    setIsSearchEnabled,
    isMobile,
    isPhone,
    isTablet,
  } = props;

  const router = useRouter();
  const cityId = useRegionId();

  const {
    layout: { footer },
  } = useAppUi();

  useViewportWatcher();

  const { domain, region, regions, filters, articles, host, user } = useContext(PagePropsContext);

  const [isDemonstrationBlink, setIsDemonstrationBlink] = useCookie(COOKIE_NAME.FEATURE_DEMONSTRATION_BLINK, '');

  const { banner, setBanner } = useAppUi();

  const [allArticles, setAllArticles] = useState(articles);
  const [bannerObserver] = useState();
  const [productItemObserver, setProductItemObserver] = useState();

  {
    const region = useAppStore((state) => state.region);
    if (isClientDebug()) {
      console.log('[appStore][region]', region);
    }
  }

  const renderFooter =
    footer.active &&
    router.pathname !== '/cart/[cartPage]' &&
    router.query.cartPage !== 'purchase' &&
    router.pathname !== '/cart' &&
    router.pathname !== '/checkout';

  const handleGetAllArticles = async () => {
    if (!allArticles?.length) {
      const items = await getAllArticles({ cityId: region?.id });

      setAllArticles(items?.blog?.data || []);
    }
  };

  useEffect(() => {
    handleGetAllArticles();
  }, []);

  const isDebug = router?.query?.debug;

  const koverHost = `https://${host}`;

  const isAmp = useAmp();

  const koverHelperRef = useRef();

  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    trackPageView(window?.location?.href, regions);
  }, [regions]);

  useRouteEvent({
    onStart: (url) => {
      setIsProgress(true);
      trackPageView(url, regions);
    },
    onEnd: () => setIsProgress(false),
  });

  useGclid(router);

  const dataLayerListener = (model, message) => {
    // Message has been pushed.
    // The helper has merged it onto the model.
    // Now use the message and the updated model to do something.

    if (isDebug) {
      console.log('koverDataLayer', message);
    }

    if (message?.length) {
      const [cmd, fn, ...options] = message;

      if (cmd === 'leadrive:init' && typeof fn === 'function') {
        if (isDebug) {
          console.log('leadrive:init', fn, 'with', options);
        }

        fn(...options);
      }

      if (cmd === 'leadrive:ready-callback') {
        if (isDebug) {
          console.log('leadrive:ready-callback');
        }

        setIsSearchEnabled(true);
      }
    }
  };

  const dataLayerInitialize = async () => {
    await import('../../../../libs/data-layer-helper');

    if (global?.window?.DataLayerHelper && !koverHelperRef.current) {
      koverHelperRef.current = new global.window.DataLayerHelper(global.window?.koverDataLayer, {
        listener: dataLayerListener,
        listenToPast: true,
      });
    }
  };

  const handleProductItemObserver = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target.classList.contains('product-item')) {
        global.window?.dataLayer?.push({
          event: 'view_item_list',
          ecommerce: {
            impressions: [entry.target.metricProduct],
          },
        });

        observer.unobserve(entry.target);
      }
    });
  };

  const handleGtmLoad = useCallback((event) => {
    if (
      (event.origin === 'https://kover.ru' || event.origin === 'https://stage.kover.ru') &&
      event.data?.type === 'GTM_LOAD'
    ) {
      if (router.query?.ldmsg) {
        const isSalesMan = Cookie.get(cookies.isSalesMan);

        if (!isSalesMan) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { ldmsg, ...newQuery } = router.query;

          router.replace(
            {
              pathname: router.pathname,
              query: newQuery,
            },
            null,
            { shallow: true }
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    dataLayerInitialize();

    const newProductItemObserver = new IntersectionObserver(handleProductItemObserver, {
      root: null,
      rootMargin: '24px',
      threshold: 0.2,
    });

    setProductItemObserver(newProductItemObserver);

    window.addEventListener('message', handleGtmLoad, false);

    return () => window.removeEventListener('message', handleGtmLoad);
  }, []);

  useEffect(() => {
    window.koverApi = {
      push: (...options) => {
        if (options?.length) {
          if (options?.length === 1 && options[0] && options[0]?.toString().startsWith('https://')) {
            if (options[0]?.toString().startsWith(koverHost)) {
              const repairedUrl = options[0].replace(koverHost, '');

              if (isDebug) {
                console.log('Detect absolute kover url', ...options, 'Push to relative:', repairedUrl);
              }
              return router.push(repairedUrl);
            }

            if (isDebug) {
              console.log('Detect absolute alien url', ...options, 'Push to relative:', ...options);
            }

            [window.location.href] = options;

            return null;
          }

          if (isDebug) {
            console.log('Push to', ...options);
          }
          return router.push(...options);
        }

        throw new Error(
          'Invalid routing parameters, please see docs at https://nextjs.org/docs/api-reference/next/router#routerpush'
        );
      },
    };
  }, [favorites]);

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 27:
        hideModal();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log('Development by Alekseev Ruslan and UserStory');

    console.info(
      `Version info\nBranch: ${process.env.NEXT_PUBLIC_BRANCH_NAME}\nHash: ${process.env.NEXT_PUBLIC_COMMIT_HASH}\nDate: ${process.env.NEXT_PUBLIC_COMMIT_DATE}`
    );

    if (isDebug) {
      console.log('Info:', info);
    }

    // getFavoritesRequest();

    // setIsRendered(true);

    document.addEventListener('keydown', handleKeyDown);

    console.log('exp ver', experimentVersion, isNil(experimentVersion));
    if (experimentId && !isNil(experimentVersion)) {
      Cookie.set(`experiment-${experimentId}`, experimentVersion, { domain });

      window.dataLayer?.push({
        event: 'optimizeEvent',
        eventCategory: 'Experiments',
        eventAction: 'Activation',
        eventLabel: experimentId,
        experimentId,
        experimentVersion,
      });
    }

    const savedRegionId = Cookie.get(cookies.currentRegionId);

    if (isDebug) {
      console.log('Saved region id', savedRegionId, region?.id);
    }

    if (!savedRegionId && region?.id) {
      const baseDomain = getBaseDomain({ regions, host: global?.window?.location.host });

      global.window?.localStorage?.setItem(cookies.currentRegionId, region?.id);
      Cookie.set(cookies.currentRegionId, region?.id, { domain: baseDomain });
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const blackFridayValue = global.window?.localStorage?.getItem('blackFriday');
    const blackFriday = blackFridayValue ? JSON.parse(blackFridayValue) : {};
    const isBlackFridayAllowed = cityId !== 42 && cityId !== 44 && cityId !== 49; // 42, 44, 49

    if (blackFriday?.timestamp) {
      if ((blackFriday.state === BlackFridayStage.START || blackFriday.state === BlackFridayStage.PAUSE) && (Date.now() > (blackFriday.timestamp + 1000 * 60 * 60 * 24 * 3))) {
        global.window?.localStorage?.setItem('blackFriday', JSON.stringify({
          timestamp: Date.now(),
          state: BlackFridayStage.STOP,
        }));

        setBanner({
          id: BANNER_ID.BLACK_FRIDAY,
          value: {
            timestamp: Date.now(),
            state: BlackFridayStage.STOP,
          },
        });
      } else if (isBlackFridayAllowed) {
        setBanner({
          id: BANNER_ID.BLACK_FRIDAY,
          value: blackFriday,
        });
      }
    }
  }, [setBanner, cityId]);

  const errorCode = get(error, 'status');

  const activeModalChild = get(modalChildrens, [modalChildrens.length - 1]);
  const needHeader =
    router.pathname !== '/cart/[cartPage]' &&
    router.query.cartPage !== 'purchase' &&
    router.pathname !== '/cart' &&
    router.pathname !== '/checkout';

  const needDemonstrationBanner =
    router.pathname === '/catalog/[[...splat]]' ||
    router.pathname === '/product/[id]/[[...splat]]';

  const needBlackFriday = banner?.id === BANNER_ID.BLACK_FRIDAY && ((banner?.value?.state === BlackFridayStage.START || banner?.value?.state === BlackFridayStage.PAUSE) && (banner?.value?.timestamp ?? 0) + 1000 * 60 * 60 * 24 * 3);

  let renderedError = null;

  if (errorCode && isError) {
    if (errorCode === 404) {
      renderedError = <NotFound statusCode={404} />;
    } else {
      renderedError = <Error statusCode={errorCode} />;
    }
  }

  const logoScriptData = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: koverHost,
    logo: `https://${host || 'kover.ru'}/static/imgs/logo.svg`,
  });

  const searchScriptData = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Website',
    url: koverHost,
    potentialAction: {
      '@type': 'SearchAction',
      target: `https://${host || 'kover.ru'}/search/?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  });

  const userAgentInfo = useMemo(() => ({ isMobile, isTablet, isPhone }), [isMobile, isTablet, isPhone]);

  if (isAmp) {
    return (
      <>
        <Head>
          <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: logoScriptData }} />
        </Head>

        <div
          className={classnames(styles.layout, 'diginetica-main-settings')}
          data-region-id={info?.digineticaSettings?.feedId || ''}
        >
          {needHeader && <Header info={info} favorites={favorites} isAmp={isAmp} />}

          <div className={styles.layoutContent}>
            {isError ? renderedError : children}
            <Footer footerData={footerData} showModal={showModal} isAmp={isAmp} />
          </div>
        </div>
      </>
    );
  }

  const defaultWidth = getDefaultWidth({ isPhone, isTablet });

  const leadriveSearchScript = `window.leadriveCityId = ${region?.coordinates ? 1 : 2};window.leadriveCityId2 = ${
    region?.leadriveCityId || 28
  };`;
  const userGTMScript = user?.id
    ? `(window.dataLayer = window.dataLayer || []).push({ 'user_id' : ${user?.id} });`
    : '';

  const koverData = {};
  const isLegacyStyle = isLegacyStyleEnabled(router.pathname);

  let preparedKoverData = '';

  try {
    if (filters) {
      koverData.filters = filters;
    }

    preparedKoverData = JSON.stringify(koverData);
  } catch (e) {
    console.log(e);
  }

  const koverDataScript = `window.koverData = ${preparedKoverData};`;

  const layoutClassName = classnames(
    styles.layout,
    {
      [styles.padded]: needHeader,
      [styles.demonstrationBanner]: !+isDemonstrationBlink && needDemonstrationBanner && !needBlackFriday,
      [styles.blackFridayBanner]: needBlackFriday,
      [styles.legacy]: isLegacyStyle,
    },
  );

  return (
    <>
      <Head>
        <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: logoScriptData }} />
        <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: searchScriptData }} />
        <script dangerouslySetInnerHTML={{ __html: leadriveSearchScript }} />
        <script dangerouslySetInnerHTML={{ __html: userGTMScript }} />
        <script dangerouslySetInnerHTML={{ __html: koverDataScript }} />
      </Head>

      {isLegacyStyle && <style dangerouslySetInnerHTML={{ __html: legacyStyle }} />}

      <BannerObserverContext.Provider value={bannerObserver}>
        <ProductItemObserverContext.Provider value={productItemObserver}>
          <InfoContext.Provider value={info}>
            <UserAgentContext.Provider value={userAgentInfo}>
              <div className={layoutClassName}>
                <div className={classnames(styles.layoutInner, { [styles.legacy]: isLegacyStyle })}>
                  {needHeader && (
                    <Header
                      allArticles={allArticles}
                      onGetAllArticles={handleGetAllArticles}
                      info={info}
                      favorites={favorites}
                      contactsData={contactsData}
                      isPhone={isPhone}
                      isTablet={isTablet}
                      isRegionConfirmationShowed={isRegionConfirmationShowed}
                    />
                  )}

                  <div className={styles.layoutContent} key='content'>
                    <DefaultWidthContext.Provider value={defaultWidth}>
                      {isError ? renderedError : children}
                    </DefaultWidthContext.Provider>

                    {renderFooter && (
                      <Footer
                        contacts={contacts}
                        contactsData={contactsData}
                        footerData={footerData}
                        showModal={showModal}
                      />
                    )}
                  </div>

                  {!!needDemonstrationBanner && !needBlackFriday && <DemonstrationBanner />}
                  {!!needBlackFriday && <BlackFridayBanner />}

                  {!!modalChildrens.length && (
                    <DynamicDialog
                      hideModal={hideModal}
                      router={router}
                      activeModalChild={activeModalChild}
                      modalChildrens={modalChildrens}
                    />
                  )}

                  <Progress isAnimating={isProgress} />
                </div>
              </div>
            </UserAgentContext.Provider>
          </InfoContext.Provider>
        </ProductItemObserverContext.Provider>
      </BannerObserverContext.Provider>
    </>
  );
};

Layout.propTypes = {
  footer: PropTypes.object,
  children: PropTypes.node.isRequired,
  modalChildrens: PropTypes.array,
  router: PropTypes.object.isRequired,
  showModal: PropTypes.func,
  info: PropTypes.object,
  cabinet: PropTypes.object,
  debugPayload: PropTypes.object,
  isError: PropTypes.bool,
  error: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  getFavoritesRequest: PropTypes.func.isRequired,
};

Layout.defaultProps = {
  footer: null,
  footerMenuItems: null,
  error: null,
  isError: false,
  showModal: false,
  cabinet: null,
  modalChildrens: [],
  info: null,
  debugPayload: null,
};

export default withRouter(Layout);
