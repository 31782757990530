const RESPONSE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

export enum CACHE_LIFETIME {
  ZERO = 0,
  SECOND = 1,
  MINUTE = 60,
  HOUR = 60 * 60,
  DAY = 60 * 60 * 24,
  WEEK = 60 * 60 * 24 * 7,
  MONTH = 60 * 60 * 24 * 30,
}

export enum CACHE_TAG {
  BASE = 'base',
  BASE_COMMON = 'base-common',
  BASE_REGION = 'base-region',
  ARTICLES = 'articles',
}

export default RESPONSE_STATUS;
