'use client';

import React, { useState } from 'react';
import Countdown from 'react-countdown';
import classNames from 'classnames';

import closeIcon from '@/assets/icons/close.svg';
import copyIcon from '@/assets/icons/copy.svg';
import useRegionId from '@/core/model/region/store/useRegionId';
import { BANNER_ID, BlackFridayPromocodeType, BlackFridayStage } from '@/core/store/ui';
import useAppUi from '@/core/store/ui/useAppUi';
import SvgIcon from 'components/SvgIcon';

import style from './BlackFridayBanner.module.css';


const CountdownRenderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className={style.countdown}>
      <div className={style.item}>
        <span className={style.value}>{days}</span>
        <span className={style.legend}>дн</span>
      </div>
      <div className={style.item}>
        <span className={style.value}>{hours}</span>
        <span className={style.legend}>ч</span>
      </div>
      <div className={style.item}>
        <span className={style.value}>{minutes}</span>
        <span className={style.legend}>м</span>
      </div>
      <div className={style.item}>
        <span className={style.value}>{seconds}</span>
        <span className={style.legend}>с</span>
      </div>
    </div>
  );
}

const BlackFridayBanner = ({ mode }: { mode?: 'header' }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { banner, setBanner } = useAppUi();
  const cityId = useRegionId();

  const handleClose = () => {
    const blackFridayValue = global.window?.localStorage?.getItem('blackFriday');
    const blackFriday: any = blackFridayValue ? JSON.parse(blackFridayValue) : {};

    global.window?.localStorage?.setItem('blackFriday', JSON.stringify({
      timestamp: Date.now(),
      state: blackFriday.state === BlackFridayStage.START ? BlackFridayStage.PAUSE : BlackFridayStage.STOP,
    }));

    setBanner(undefined);

    setTimeout(() => {
      const blackFridayValue = global.window?.localStorage?.getItem('blackFriday');
      const blackFriday: any = blackFridayValue ? JSON.parse(blackFridayValue) : {};

      setBanner({
        id: BANNER_ID.BLACK_FRIDAY,
        value: blackFriday,
      });
    }, 1000 * 10);
  };

  const coupon = banner?.value?.type === BlackFridayPromocodeType.LUX ? 'LUXEDEC' : 'PROMODEC';
  const promo = isCopied ? 'Скопировано' : coupon;

  const handleCopy = () => {
    navigator.clipboard.writeText(coupon);

    setIsCopied(true);
  };

  const isBlackFridayAllowed = cityId !== 42 && cityId !== 44 && cityId !== 49; // 42, 44, 49

  const isOpen = isBlackFridayAllowed
    && (
      (
        banner?.value?.state === BlackFridayStage.START
        || banner?.value?.state === BlackFridayStage.PAUSE
      )
      && (Date.now() < ((banner?.value?.timestamp ?? 0) + 1000 * 60 * 60 * 24 * 3))
    );

  return isOpen && (
    <div className={classNames(style.root, { [style.headerMode]: mode === 'header' })}>
      <span />

      <div className={style.content}>
        <div className={style.header}>
          <span>Специальная скидка&nbsp;<strong>{banner?.value?.type === BlackFridayPromocodeType.LUX ? '10%' : '20%'}</strong>&nbsp;по промокоду</span>
          <button type='button' className={style.copyElement} onClick={handleCopy}>{promo}</button>
          <button type='button' className={style.copyButton} onClick={handleCopy}>
            <SvgIcon className={style.copyIcon} svg={copyIcon} />
          </button>
          Скопируйте промокод и примените его в корзине <strong>прямо сейчас! </strong>
          Действует <strong>на все товары</strong>{banner?.value?.type === BlackFridayPromocodeType.LUX ? ' от 500' : ' до 100'} тыс. ₽, не участвующие в акциях!
        </div>

        <Countdown
          date={new Date(2025, 0, 1, 0, 0, 0, 0)}
          renderer={CountdownRenderer}
        />
      </div>

      <button type='button' className={style.closeButton} onClick={handleClose}>
        <SvgIcon svg={closeIcon} />
      </button>
    </div>
  );
}

export default BlackFridayBanner;
