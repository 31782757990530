export const APP_SECTION = {
  ADRESA_MAGAZINOV: 'adresa-magazinov',
  CATALOG: 'catalog',
  CART: 'cart',
  CONTACTS: 'kontakty',
  DECORATORS: 'decorators',
  DESIGNER: 'designer',
  INFLUENSERS: 'influensers',
  FAVORITES: 'favorites',
  FAQ: 'faq',
  MODELI: 'modeli',
  PRODUCT: 'product',
  VOZVRAT: 'vozvrat',
};

export const SECTION_TO_ROUTE = {
  [APP_SECTION.ADRESA_MAGAZINOV]: `/adresa-magazinov`,
  [APP_SECTION.CATALOG]: `/catalog`,
  [APP_SECTION.CART]: `/cart`,
  [APP_SECTION.CONTACTS]: `/kontakty`,
  [APP_SECTION.DESIGNER]: `/designer`,
  [APP_SECTION.INFLUENSERS]: `/influensers`,
  [APP_SECTION.DECORATORS]: `/decorators`,
  [APP_SECTION.FAVORITES]: `/favorites`,
  [APP_SECTION.MODELI]: `/modeli`,
  [APP_SECTION.PRODUCT]: `/product`,
  [APP_SECTION.FAQ]: `/faq`,
  [APP_SECTION.VOZVRAT]: `/vozvrat`,
};
