import { useEffect } from 'react';

import { useAppStoreApi } from '@/core/store/AppStoreProvider';
import useHasMounted from '@/shared/hooks/useHasMounted';
import { useIsDesktop, useIsMobile, useIsTablet } from '@/shared/hooks/useMediaQuery';

const useViewportWatcher = () => {
  const { setState } = useAppStoreApi();
  const hasMounted = useHasMounted();

  const isMobile = useIsMobile() ?? false;
  const isTablet = useIsTablet() ?? false;
  const isDesktop = useIsDesktop() ?? false;

  useEffect(() => {
    if (!hasMounted) return;

    const update = {
      isMobile,
      isPhone: isMobile,
      isTablet,
      isDesktop,
    };

    setState((state) => {
      state.client = {
        ...state.client,
        ...update,
      };
    });
  }, [hasMounted, isMobile, isTablet, isDesktop]);

  return null;
};

export default useViewportWatcher;
