const isSalesMan = 'issalesman';

const coupon = 'couponCode';
const currentRegionId = 'regionId';
const deliveryType = 'deliveryType';
const isHowToFitShowed = 'isHowToFitShowed';
const isInstallmentRequired = 'isInstallmentInCartRequired';
const isRegionConfirmationShowed = 'isRegionConfirmed';
const isThanksForSubscribingShowed = 'isThanksForSubscribingShowed';
const phpSessId = 'PHPSESSID';

export default {
  phpSessId,

  isSalesMan,

  coupon,
  currentRegionId,
  deliveryType,
  isHowToFitShowed,
  isInstallmentRequired,
  isRegionConfirmationShowed,
  isThanksForSubscribingShowed,
};
