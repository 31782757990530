import { GetServerSidePropsContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import setCookieParser from 'set-cookie-parser';

export type CookieValue = string | number;

export type BaseCookieOptions = {
  path: string;
  expires: Date;
  maxAge: number;
  domain: string;
  secure: boolean;
  httpOnly: boolean;
  sameSite: 'strict' | 'lax' | 'none';
};

export interface SetCookieOptions extends BaseCookieOptions {
  ctx: GetServerSidePropsContext;
}

export type ParseCookieOptions = {
  decode(value: string): string;
};

export interface DestroyCookieOptions extends BaseCookieOptions {
  encode?(value: string): string;
  ctx: GetServerSidePropsContext;
}

class CookieService {
  static stringify = (cookies: Record<string, string>) =>
    Object.keys(cookies)
      .map((v) => `${encodeURIComponent(v)}=${encodeURIComponent(cookies[v])}`)
      .join('; ');

  stringify = (cookies: Record<string, string>) =>
    Object.keys(cookies)
      .map((v) => `${encodeURIComponent(v)}=${encodeURIComponent(cookies[v])}`)
      .join('; ');

  setValue = (name: string, value: string, options?: Partial<SetCookieOptions>) => {
    const { ctx, ...restOptions } = options ?? {};

    if (ctx) {
      setCookie(ctx, name, value, restOptions);
      return;
    }

    setCookie(null, name, value, options);
  };

  parseClientCookies = (cookies: string) => {
    if (!cookies) {
      return {};
    }

    return cookies
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc, v) => {
        const key = decodeURIComponent(v[0].trim());
        const value = decodeURIComponent(v[1].trim());

        acc[key] = value;
        return acc;
      }, {} as Record<string, string>);
  };

  parseServerCookies = (ctx: GetServerSidePropsContext, options?: Partial<ParseCookieOptions>) => {
    if (!ctx) {
      console.log('[core][api][client][set cookie] ctx is undefined');
      return {};
    }

    return parseCookies(ctx, options);
  };

  parseSetCookie = (cookies: string[] | string) => {
    if (!cookies) {
      return {};
    }

    return setCookieParser.parse(cookies);
  };

  destroyValue(name: string, options?: Partial<DestroyCookieOptions>) {
    if (!options?.ctx) {
      console.log('[core][api][client][destroy cookie] ctx is undefined');
      return;
    }

    const { ctx, ...restOptions } = options;

    if (ctx) {
      destroyCookie(ctx, name, restOptions);
      return;
    }

    destroyCookie(null, name, options);
  }
}

export default CookieService;
