/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';

import ClickOutside from '../../../ClickOutside';
import DropdownWrapper from '../DropdownWrapper';

import styles from './HeaderDropdownAction.module.css';

const HeaderDropdownAction = (props) => {
  const {
    href,
    name,
    title,
    color,
    wide,
    icon,
    badge,
    isOpen,
    theme,
    prefetch,
    dropdown,
    className,
    actionClassName,
    popupProps = {},
    onToggleDropdown,
    label,
  } = props;

  const headerDropdownActionRef = useRef();

  const [isHover, setIsHover] = useState(false);

  const trackFirstLevelClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Главное меню',
      eventAction: 'Первый уровень',
      eventLabel: name,
    });
  };

  const handleClickOutside = () => {
    if (isOpen) {
      if (onToggleDropdown) {
        onToggleDropdown({ isOpen: false });
        setIsHover(false);
      }
    }
  };

  const handleCloseClick = () => {
    if (onToggleDropdown) {
      onToggleDropdown({ isOpen: false });
      setIsHover(false);
    }
  };

  const handleOpenClick = () => {
    trackFirstLevelClick();

    if (onToggleDropdown) {
      onToggleDropdown({ isOpen: !isOpen });
    }
  };

  let action;
  let content;

  if (theme === 'icon') {
    content = (
      <>
        <div className={styles.headerDropdownActionIcon}>
          {icon}
          {!!badge && <span className={styles.headerDropdownActionBadge}>{badge}</span>}
        </div>
        <span className={styles.headerDropdownActionIconTitle}>{title}</span>
      </>
    );
  } else {
    content = (
      <>
        {!!icon && <div className={styles.headerDropdownActionIcon}>{icon}</div>}
        {!!title && <span className={styles.headerDropdownActionIconTitle}>{title}</span>}
      </>
    );
  }

  if (href) {
    action = (
      <Link
        aria-label={label}
        href={href}
        prefetch={prefetch}
        className={classnames(styles.headerDropdownActionTitle, actionClassName)}
        onClick={() => {
          trackFirstLevelClick({ label: title });
        }}
      >
        {content}
      </Link>
    );
  } else {
    action = (
      <button
        aria-label={label}
        type='button'
        className={classnames(styles.headerDropdownActionTitle, actionClassName)}
        onClick={handleOpenClick}
      >
        {content}
      </button>
    );
  }

  return (
    <ClickOutside
      wrapperRef={headerDropdownActionRef}
      handleClickOutside={handleClickOutside}
      className={classnames(
        styles.headerDropdownAction,
        {
          [styles.active]: isOpen || isHover,
          [styles.normal]: !theme,
          [styles.icon]: theme === 'icon',
          [styles.warning]: color === 'warning',
        },
        className
      )}
    >
      {action}

      {dropdown && (
        <DropdownWrapper
          parentRef={headerDropdownActionRef}
          mode={theme === 'icon' ? 'popup' : null}
          wide={wide}
          isOpen={isOpen || isHover}
          onCloseClick={handleCloseClick}
          {...popupProps}
        >
          {dropdown}
        </DropdownWrapper>
      )}
    </ClickOutside>
  );
};

HeaderDropdownAction.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  wide: PropTypes.bool,
  prefetch: PropTypes.bool,
  isOpen: PropTypes.bool,
  isHoverable: PropTypes.bool,
  icon: PropTypes.element,
  badge: PropTypes.number,
  theme: PropTypes.string,
  dropdown: PropTypes.element,
  className: PropTypes.string,
  actionClassName: PropTypes.string,
  popupProps: PropTypes.object,
  onToggleDropdown: PropTypes.func,
  onMouseEnter: PropTypes.func,
};

HeaderDropdownAction.defaultProps = {
  href: null,
  name: null,
  prefetch: true,
  title: null,
  color: null,
  wide: false,
  isOpen: false,
  isHoverable: false,
  icon: null,
  badge: null,
  theme: null,
  dropdown: null,
  className: null,
  actionClassName: null,
  popupProps: {},
  onToggleDropdown: null,
  onMouseEnter: null,
};

export default HeaderDropdownAction;
